@import "styles/mixins";

.product-catalog {
    background-color: var(--white);
    padding: 24px;
    border-radius: 8px;
    gap: 24px;
    display: grid;
    grid-template-columns: calc(min(30%, 270px)) 1fr;
    transition: background-color 0.3s ease-out;
    img {
        width: 100%;
        border-radius: 8px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 1 / 1;
    }

    &__text {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        max-width: 344px;
        width: 100%;
    }

    &__buttons {
        margin-top: auto;
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
    &:hover {
        background-color: var(--greyHover);
    }

    @include w($md) {
        padding: 16px;
        grid-template-columns: 1fr;
        img {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
        }
        &__text {
            max-width: none;
            text-align: justify;
            grid-column: 1 / 2;
            grid-row: 1 / 2;
        }
        &__buttons {
            grid-column: 1 / 2;
            grid-row: 3 / 4;
            flex-direction: column;

            .button {
                margin-top: 0;
            }
        }
    }
}
